.form-item.ant-form-item {
  padding: 16px 0;
  border-bottom: 1px solid #dbdbdb;
  &.borderless {
    border-bottom: none;
  }
  .ant-form-item-label > label {
    color: #686868;
    font-weight: 700;
    pointer-events: none;
  }
  .ant-input-password-icon {
    color: $primary-color;
    cursor: pointer;
  }
  .ant-picker {
    width: 100%;
  }
  // upload
  .ant-upload-list-picture-card-container,
  .ant-upload-select-picture-card {
    width: 160px;
    height: 160px;
    .ant-btn-icon-only.ant-btn-sm {
      width: 22px;
      padding: 0;
    }
  }
}

.form-filter.ant-form-item {
  .ant-form-item-label {
    padding: 0 0 8px 0;
  }
  .ant-form-item-control-input {
    min-height: 36px;
  }
  .ant-form-item-label > label {
    color: #686868;
    font-weight: 700;
    font-size: 12px;
  }
  .ant-picker {
    width: 100%;
  }
}

.form-detail {
  padding: 16px 0;
  border-bottom: 1px solid #dbdbdb;
  &.borderless {
    border-bottom: none;
  }
  .label {
    font-size: 16px;
    color: #686868;
    font-weight: 700;
  }
  .value-detail {
    white-space: pre-line;
  }
  .image-detail {
    .ant-image {
      padding: 8px;
      margin: 0 8px 8px 0;
      border: 1px solid #cfcfcf;
      border-radius: 4px;
      width: 160px;
      height: 160px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
