.lading-create {
  padding: 30px;

  .change-step-bottom {
    position: fixed;
    z-index: 10;
    background-color: #fff;
    bottom: 0;
    text-align: center;
    padding: 15px;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
    .back-btn {
      position: absolute;
      left: 20px;
      display: flex;
      align-items: center;
    }
  }
  .collapsed {
    width: calc(100vw - 80px);
    left: 80px;
  }
  .un-collapsed {
    width: calc(100vw - 240px);
    left: 240px;
  }

  .list-slots {
    .ant-picker-small {
      width: 100%;
    }
  }

  .setup-time-create {
    .week-day-checkbox {
      .ant-form-item-control-input {
        align-items: flex-start;
      }
      .ant-form-item {
        padding: 0;
      }
    }
  }

  .lading-confirm {
    .time-confirm {
      padding: 24px 32px;
      border: #dbdbdb 2px solid;
      border-radius: 8px;
      .time-period {
        border-top: 1px solid #dbdbdb;
      }
    }
  }
}
