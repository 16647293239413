.operation-management {
  .patel-trip {
    box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid #686868;
    border-radius: 3px;
    position: relative;
    .icon-close {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
    .text-sw {
      color: #adadad;
      margin-bottom: 3px;
    }
    .text-choose {
      svg {
        scale: 1.25;
        path {
          fill: #d67411;
        }
      }
    }
    .infor-patel-service {
      font-size: 14px;
      .infor-base {
        max-width: 205px;
      }
      path {
        fill: #686868;
      }
      .icon-car-type {
        scale: 0.8;
      }
    }
    .btn-edit-route {
      width: 160px;
    }
    .patel-tabs-wrapper {
      width: 100%;
      .ant-tabs-nav {
        margin-left: 20px;
        margin-right: 20px;
      }
      .ant-tabs-content-holder {
        position: relative;
        height: calc(100vh - 485px);
        margin-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        overflow-y: overlay;
      }
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          width: 50%;
          div {
            width: 100%;
            text-align: center;
            color: #cfcfcf;
            font-weight: 700;
          }
        }
        .ant-tabs-tab-active {
          div {
            color: #171717;
            font-weight: 700;
          }
        }
      }
      .tab-spot,
      .tab-trip {
        .patel-infor-spot,
        .patel-infor-trip {
          &.grabbable {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            &:focus {
              outline: none !important;
            }
          }

          /* (Optional) Apply a "closed-hand" cursor during drag operation. */
          &.grabbable:active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
          }
          &.trip-spot-active {
            border: 2px solid #d67411;
            .order-number {
              background: #d67411 !important;
            }
          }
          &.isDragging {
            z-index: 9999999;
          }
          position: relative;
          border: 2px solid #dbdbdb;
          border-radius: 4px;
          padding: 0 12px 12px;
          background-color: white;
          .header-spot,
          .header-trip {
            border-bottom: 1px solid #dbdbdb;
            height: 44px;
            .order-number {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              font-weight: 700;
              font-size: 12px;
              line-height: 125%;
              color: #ffffff;
              background: $primary-color;
              margin-right: 6px;
              &.active {
                background: #d67411;
              }
            }
            .schedule-time {
              font-weight: 700;
              font-size: 14px;
              width: 52px;
            }
            .tag-workkind {
              font-weight: 700;
              font-size: 14px;
              width: 40px;
              height: 18px;
              border-radius: 5px;
              &.pickup {
                color: #2e91f3;
                border: 1px solid #2e91f3;
              }
              &.delivery {
                color: #00b957;
                border: 1px solid #00b957;
              }
            }
            .status-spot {
              font-size: 14px;
              max-width: 80px;
            }
          }
          .body-trip {
            .schedule-detail {
              .detail {
                color: #686868;
                font-size: 14px;
                .name {
                  max-width: 100px;
                }
              }
            }
          }
          .arrow-down {
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: -26px;
          }
        }
      }
    }
  }
  .infor-lot {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -170%);
    background: #686868;
    border-radius: 32px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    width: max-content;
    padding: 8px 32px;
    text-align: center;
  }
  .car-map-marker-wrapper-highlighted {
    .infor-lot {
      top: 0;
      left: 0;
      transform: translate(-50%, -200%);
      background: #d67411;
    }
  }
  .car-map-marker-wrapper {
    &.car-map-marker-inactive {
      .infor-lot {
        background: #cfcfcf;
      }
      .map-marker {
        border: 2px solid #cfcfcf;
        svg {
          path {
            fill: #cfcfcf;
          }
        }
      }
      .map-marker-con,
      .map-marker-pointer {
        border-color: #cfcfcf;
      }
    }
  }
  .route-map {
    .btn-update {
      z-index: 1;
      top: 12px;
      right: 64px;
    }
    &.hide-infor-marker {
      .infor-lot {
        display: none;
      }
    }
  }
}

.tab-panel-bc-order {
  &__container {
    border: 2px solid #dbdbdb;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 12px;
    row-gap: 4px;

    &--active {
      border: 2px solid #d67411;
    }
  }

  &__last-item {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 32px;
  }
}