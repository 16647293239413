.calculation-report-list {
  padding: 30px 40px;
  .form-info-calculation-report {
    padding: 16px 24px;
    border-radius: 4px;
    margin-top: 25px;
    background-color: #f7f7f7;
    .ant-divider {
      margin: 0;
    }
    .ant-row span:nth-child(1) {
      font-weight: 700;
      font-size: 14px;
      color: #686868;
      width: 120px;
    }
    .ant-row span:nth-child(2) {
      font-weight: 400;
      font-size: 16px;
      color: #171717;
    }
  }
}
.calculation-detail {
  .calculation-detail-wraper.ant-row {
    flex-flow: nowrap !important ;
  }
  .ant-form-item-control-input {
    min-height: 0 !important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.modal-create-invoice {
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

.multi-down-report {
  position: fixed;
  bottom: 0;
  left: 150px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  height: 64px;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;

  .icon-download {
    path {
      fill: #ffffff;
    }
  }
}
