@import '~antd/dist/antd.less';

// overwrite ant style here
// helpful link: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@font-family: 'Noto Sans JP', sans-serif;
@primary-color: #0076ce;
@text-color: #171717;
@border-color-base: #cfcfcf;
@border-radius-base: 4px;
@line-height-base: 1.375;

@disabled-bg: #f7f7f7;
@disable-color: #999999;

// font-size rules
@font-size-base: 16px;
@font-size-lg: @font-size-base;
@font-size-sm: 14px;

// height rules
@height-base: 48px;
@height-lg: 56px;
@height-sm: 36px;

// Layout
@layout-body-background: white;
@layout-header-background: #ffffff;
@layout-sider-background: #e6f4ff;
@layout-header-padding: 0 30px;

// Breadcrumb
@breadcrumb-base-color: red;
@breadcrumb-last-item-color: #8f8f8f;
@breadcrumb-font-size: 18px;
@breadcrumb-link-color: #0076ce;
@breadcrumb-separator-margin: 0 8px;

// button
@btn-font-weight: 500;
@btn-primary-color: #ffffff;
@btn-border-width: 1px;
@btn-line-height: 1;
@btn-font-size-sm: 14px;
@btn-shadow: 0px 2px 6px rgba(3, 20, 38, 0.23);
@btn-primary-shadow: 0px 2px 6px rgba(3, 20, 38, 0.23);

@btn-default-color: @primary-color;
@btn-default-bg: #ffffff;
@btn-default-border: #0076ce;

@btn-danger-color: #ffffff;
@btn-danger-bg: #c53f3f;
@btn-danger-border: none;

.ant-btn-dashed {
  color: #0076ce;
  background-color: #f0f9ff;
  border: 1px dashed #0076ce;
  box-shadow: 0px 2px 6px rgba(3, 20, 38, 0.23);
}

//select
.ant-select {
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-radius: 4px;
      border-color: #0078cf;
    }
  }
  .ant-select-selector {
    border-radius: 4px !important;
    box-shadow: none !important;
  }
}
.ant-select-dropdown {
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .ant-select-item-option {
    min-height: 40px;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
  }
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

.ant-select.ant-select-show-search {
  span[aria-live='polite'] + .ant-select-selector input {
    color: transparent;
  }
}

.ant-select.ant-select-show-search {
  span[aria-live='polite'] + .ant-select-selector input#autocomplete-select {
    color: black;
  }
}

.ant-select-selection-search {
  input {
    border: 1px solid #cfcfcf;
  }
}

// input
@input-placeholder-color: #8f8f8f;
@input-border-color: #e3e3e3;
@input-height-base: 48px;

// form
@form-item-margin-bottom: 0;
.ant-form-item-explain,
.ant-form-item-extra {
  color: @text-color;
  margin-top: 10px;
}

// LINK
@link-color: #0078cf;

// radio
@radio-size: 18px;
@radio-group-item-margin-right: 8px;

// checkbox
@checkbox-size: 18px;
@checkbox-group-item-margin-right: 8px;

// Tabs
@tabs-card-gutter: 6px;

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
  .ant-tabs-tab {
    min-width: 130px;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    border-radius: 0;
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #ffffff;
      }
    }
  }
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #0076ce;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}
@tabs-ink-bar-color: #D67411;

// table
@table-padding-vertical: 10px;
@table-header-bg: #f7f7f7;
@table-body-sort-bg: transparent;
@table-header-color: #171717;
@table-font-size: 14px;
@table-border-color: #dbdbdb;
@table-header-cell-split-color: transparent;
.ant-table-thead {
  tr th {
    border-bottom: 2px solid #0076ce;
    font-weight: 400;
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
.ant-table-column-sorters {
  justify-content: unset;
  .ant-table-column-title {
    flex: unset;
    margin-right: 5px;
  }
}
.ant-table-title {
  .table-title {
    position: absolute;
    top: -40px;
  }
}
// pagination
@pagination-item-size: 27px;
@pagination-item-bg-active: #0076ce;
.ant-pagination-item-active a {
  color: white;
}

// form
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}

// alert
@alert-success-border-color: #1e9107;
@alert-success-bg-color: #f7fff5;
@alert-success-icon-color: #1e9107;
@alert-warning-border-color: #ffaa00;
@alert-warning-bg-color: #fffcde;
@alert-warning-icon-color: #ffaa00;
@alert-error-border-color: #bf3434;
@alert-error-bg-color: #fff5f5;
@alert-error-icon-color: #bf3434;
@alert-message-color: #171717;
.ant-alert-message {
  font-weight: 500;
}

// anchor
@anchor-border-color: transparent;
@anchor-link-left: 0px;
