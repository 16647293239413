@import './variables.scss';

// reset style
#root {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

ol,
ul {
  list-style: none;
}

// width and height
$whamounts: (
  0,
  16,
  20,
  22,
  32,
  24,
  36,
  40,
  44,
  48,
  50,
  56,
  64,
  80,
  90,
  96,
  100,
  120,
  138,
  150,
  192,
  200,
  215,
  220,
  240,
  300,
  360,
  400,
  500,
  600,
  700,
  800
);
@each $space in $whamounts {
  .w-#{$space} {
    width: #{$space}px !important;
  }
  .h-#{$space} {
    height: #{$space}px !important;
  }
  .max-w-#{$space} {
    width: #{$space}px !important;
  }
}

// margin and padding
// Adjust this to include the pixel amounts you need.
$spaceamounts: (
  0,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  15,
  16,
  17,
  20,
  24,
  25,
  30,
  32,
  35,
  40,
  45,
  50,
  70,
  75,
  80,
  96,
  100,
  112
);
$sides: (top, bottom, left, right);
// example: mt-5, pb-50
@each $space in $spaceamounts {
  .ma-#{$space} {
    margin: #{$space}px !important;
  }
  .pa-#{$space} {
    padding: #{$space}px !important;
  }
  .mx-#{$space} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }
  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }
  .px-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
// font-weight
// example: fw-100, fw-900
@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: ($i * 100) !important;
  }
}

// font-size
// example: fz-9 to fz-40
@for $i from 9 through 40 {
  .fz-#{$i} {
    font-size: #{$i}px !important;
  }
  // @media only screen and (min-width: 1400px) {
  //   .fz-#{$i} {
  //     font-size: #{($i + 2)}px !important;
  //   }
  // }
  // @media only screen and (min-width: $xxl) {
  //   .fz-#{$i} {
  //     font-size: #{($i + 4)}px !important;
  //   }
  // }
}

.text-center {
  text-align: center !important;
}
.text-start {
  text-align: start !important;
}
.text-end {
  text-align: end !important;
}
.text-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.float-right {
  float: right !important;
}

.w-content {
  width: fit-content !important;
}
.w-full {
  width: 100% !important;
}
.h-full {
  height: 100% !important;
}

// border
.form-border {
  border: 2px solid #dbdbdb;
  border-radius: 8px;
}
.form-border-top {
  border: 2px solid #dbdbdb;
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.form-border-bottom {
  border: 2px solid #dbdbdb;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form-filter-border {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
.form-border-warning {
  border: 1px solid #ffaa00;
  border-radius: 4px;
}

.border-t {
  border-top: 1px solid #dbdbdb;
}
.border-b {
  border-bottom: 1px solid #dbdbdb;
}
.border-b-red {
  border-bottom: 1px solid #bf3434;
}
.border-b-blue {
  border-bottom: 1px solid #0076ce;
}
@for $i from 0 through 99 {
  .border-rds-#{$i} {
    border-radius: $i + px !important;
  }
}

// position
.pos-rel {
  position: relative !important;
}
.pos-abs {
  position: absolute !important;
}
.pos-fixed {
  position: fixed !important;
}
.pos-sticky {
  position: sticky !important;
}

.pos-center {
  transform: translate(-50%, -50%);
}
@for $i from -99 through 99 {
  .top-#{$i} {
    top: $i * 1%;
  }
  .bottom-#{$i} {
    bottom: $i * 1%;
  }
  .left-#{$i} {
    left: $i * 1%;
  }
  .right-#{$i} {
    right: $i * 1%;
  }
}

// display
.dis-flex {
  display: flex !important;
}
.dis-flex-inline {
  display: inline-block !important;
}

.justify-between {
  display: flex !important;
  justify-content: space-between;
}
.justify-center {
  display: flex !important;
  justify-content: center;
}
.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.object-fit {
  object-fit: contain;
}
.flex-align {
  display: flex !important;
  align-items: center;
}

.flex-align-end {
  display: flex !important;
  align-items: flex-end;
}

.flex-justify-end {
  display: flex !important;
  justify-content: flex-end;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-140 {
  width: 140px;
}
.mw-122 {
  min-width: 122px;
}

.display-none {
  display: none !important;
}

.flex-col {
  flex-direction: column !important;
}
.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-full {
  flex: 1 !important;
}

.pointer {
  cursor: pointer;
}

.fill-primary {
  path {
    fill: $primary-color;
  }
}

.fill-danger {
  path {
    fill: $danger-color;
  }
}

.primary-color {
  color: $primary-color !important;
}

.hight-line-cell {
  cursor: pointer;
  color: $primary-color !important;
}

.filter-color {
  background-color: #0076ce !important;
  color: #ffffff !important;
}

.alert {
  position: fixed !important;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
}

.status-tag {
  width: 90px;
  height: 22px;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.status-tag-lg {
    width: 120px;
    height: 36px;
    font-size: 16px;
  }
}

.tag-01 {
  background-color: #bf3434;
  color: #ffffff;
}
.tag-02 {
  background-color: #ffffff;
  border: 1px solid #0076ce;
  color: #0076ce;
}
.tag-03 {
  background-color: #0076ce;
  color: #ffffff;
}
.tag-04 {
  background-color: #1e9107;
  color: #ffffff;
}
.tag-05 {
  background-color: #dbdbdb;
  color: #171717;
}
.tag-06 {
  background-color: #ffffff;
  border: 1px solid #bf3434;
  color: #bf3434;
}
.tag-07 {
  background-color: #dbdbdb;
  color: #171717;
}
.tag-08 {
  background-color: #979797;
  color: white;
}

.bg-success {
  background: #1e9107 !important;
}
.bg-gray {
  background: #f7f7f7;
}
.bg-danger {
  background: #bf3434 !important;
}
.bg-warning-table {
  background: #fffcde !important;
}
.bg-cancel {
  background: #c53f3f !important;
}
.bg-primary {
  background-color: #0076ce !important;
}
.color-primary {
  color: #0076ce;
}
.color-white {
  color: #ffffff !important;
}
.color-infor {
  color: #171717 !important;
}
.color-red {
  color: #bf3434 !important;
}
.color-bgray {
  color: #686868;
}

.rotate-180 {
  transform: rotate(180deg);
}
.line-break {
  line-break: anywhere !important;
}
.wrap-text {
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.detail-point {
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-input {
        color: black;
      }
    }
    #deliveryAddress {
      padding: 0;
    }
    #pickupAddress {
      padding: 0;
    }
  }
  .tel-number .ant-col-sm-7 {
    flex: 0 0 58%;
    max-width: 58%;
  }
  .post-cd .ant-col-sm-7 {
    flex: 0 0 18%;
    max-width: 18%;
  }
}
.value-detail {
  word-wrap: break-word;
}
.block-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  -webkit-line-clamp: 3;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
 }
@media screen and (max-width: 1599px) {
  .detail-point {
    .tel-number .ant-col-sm-7 {
      flex: 0 0 67%;
      max-width: 67%;
    }
    .post-cd .ant-col-sm-7 {
      flex: 0 0 30%;
      max-width: 30%;
      margin-left: 20px;
    }
  }
}

.limit-height-children-wrap {
  height: 100%;
  overflow-y: scroll;
}

.min-height-loading-action {
  min-height: 68px;
}

.warning-trip-item {
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background: #fffcde;
  border: 1px solid #ffaa00;
  border-radius: 4px;
  padding: 14px;
  left: 0;
  right: 0;
  top: 0;
}

.disableBtn button:disabled,
button[disabled] span {
  color: #b9b9b9 !important;
}

.fill-disable path {
  fill: #b9b9b9 !important;
}

.overflow-y-auto {
  overflow-y: auto;
}
