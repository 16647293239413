@use 'sass:math';
@mixin triangle($size) {
  .step {
    position: relative;
    height: $size;
    line-height: $size;
    background: #f7f7f7;
    font-weight: 700;
    color: $primary-color;
    text-align: center;
    &.inactive {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -#{$size};
        border: solid transparent;
        border-left-color: #f7f7f7;
        border-width: calc(#{$size} / 2);
      }
    }
    &.active {
      background: #0076ce;
      color: #f7f7f7;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        border: solid transparent;
        border-left-color: #f7f7f7;
        border-width: calc(#{$size} / 2);
      }
      &::before {
        content: '';
        position: absolute;
        z-index: 9;
        top: 0;
        right: -#{$size};
        border: solid transparent;
        border-left-color: #0076ce;
        border-width: calc(#{$size} / 2);
        pointer-events: none;
      }
    }
    &:first-child {
      &::after {
        border: solid transparent !important;
      }
    }
  }
}
.my-steps {
  &.size-sm {
    @include triangle(32px);
    width: calc(100% - 16px);
    font-size: 12px;
  }
  &.size-md {
    @include triangle(48px);
    width: calc(100% - 24px);
    font-size: 20px;
  }
}
