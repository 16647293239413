.list-order {
  padding: 30px 40px;
  .form-filter-order {
    padding: 16px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-top: 25px;
    .form-filter-label {
      color: #686868;
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .pair-date-picker {
      display: flex;
      align-items: center;
    }
  }
  .ant-form-item-control-input {
    min-height: unset;
  }

  .table-list-order {
    td.ant-table-column-sort {
      background: none;
    }
    .table-title {
      position: absolute;
      top: -40px;
    }
    .over-time {
      background-color: rgba($color: rgba(240, 30, 70, 0.2), $alpha: 0.2);
    }
    .over-day {
      background-color: rgba($color: rgba(240, 40, 20, 0.4), $alpha: 0.4);
    }
    .order-yellow {
      background-color: rgba($color: rgba(245, 230, 83, 0.4), $alpha: 0.4);
    }
    .order-red {
      background-color: rgba($color: rgba(240, 40, 20, 0.4), $alpha: 0.4);
    }
    .order-pink {
      background-color: rgba($color: rgba(240, 30, 70, 0.2), $alpha: 0.2);
    }

    .ant-table-row {
      cursor: pointer;
    }
  }

  .regular-filter-btn {
    background-color: #0076ce;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
  }
}

// NOTE: テーブルの行全体がクリックできない場合、このクラスを使用してカーソルをポインターにしない
// 48 行目の cursor: pointer を変更すると影響範囲が大きいため、一時的にこのクラスを使用して対応
.ant-table-row-not-pointer {
  .ant-table-row {
    cursor: default !important;
  }
}

.order-create {
  .form-border.pl-32.pr-50.py-16 {
    .ant-form-item.form-item.borderless {
      .ant-row {
        flex-flow: nowrap !important;
      }
    }
  }
  .mr-16-percent {
    margin-left: -15% !important;
  }
  .field-parent-order {
    .ant-col-sm-12 {
      max-width: 100%;
    }
  }
}
.modal-field-parent-order {
  .ant-col.ant-form-item-control.ant-col-sm-12.ant-col-xxl-10 {
    max-width: 100%;
  }
  .ant-modal-content{
    .ant-modal-footer{
      .ant-btn-primary{
        width: 68px;
        height: 32px;
      }
    }
  }
}

.modal-upload-order {
  .ant-modal-body {
    padding: 32px 40px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    .back-btn {
      display: flex;
      align-items: center;
    }
  }
} 