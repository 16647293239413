.login {
  .background-img {
    background-image: url(../../../assets/images/login-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  .ant-input-password-icon {
    color: $primary-color;
    font-size: 14px;
    cursor: pointer;
  }

  .form-login {
    width: 80%;
    margin: 0 auto;
  }

  .reset-pwd-form {
    width: 80%;
    margin: 35px auto 0;
  }
  .info-board {
    background-color: #f7f7f7;
    padding: 20px;
    text-align: start;
    font-size: 12px;
  }
  .back-login-btn {
    width: 80%;
    margin-top: 40px;
  }
  ul {
    padding-left: 0px;
    li {
      display: flex;
      margin-bottom: 10px;
    }
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
}
