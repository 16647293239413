.time-attendance{
    padding-left: 1rem;
  }
  .form-create-attendance{
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
    .ant-col{
      .ant-form-item {
        .ant-form-item-row{
          display: flex;
          flex-direction: column;
          .ant-form-item-label {
            .ant-form-item-no-colon {
              color:#686868;
              opacity: 68%;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .borderless{
    padding-bottom: 15px;
    border-bottom: 1px solid #dbdbdb
  }
.list-order{
  .add-attendance{
    margin-right: 2.8%;
  }
}
#item_infor{
  .ant-form-item-no-colon {
    color: #171717;
    font-weight: 450;
  }
}
.btn-filter-attendances{
  padding: 15px 7px;
}
  @media screen and (max-width: 1599px) {
      .time-attendance{
        padding-left: 0.1rem;
      }
  }
  @media screen and (max-width: 1699px) {
    .list-order{
      .add-attendance{
        margin-right: 2%;
      }
    }
  }
  