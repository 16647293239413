.working-car {
  .list-lading-sider {
    height: calc(100vh - 140px);
    overflow: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background: #adadad;
    }
    &::-webkit-scrollbar-track {
      background: #e6e6e6;
    }
  }
  .pa-15.border-b.pointer {
    background-color: #fff;
    article {
      background-color: #f7f7f7;
      border-radius: 4px;
    }
  }
  .pa-15.border-b.pointer.active {
    background-color: #fbf1e7;
    article {
      background-color: #fff;
      border-radius: 4px;
    }
  }
  .ant-switch.ant-switch-checked {
    background-color: #f0bd8b;
    .ant-switch-handle::before {
      background-color: #d67411;
    }
  }
  .text-toggle {
    color: #adadad;
    position: absolute;
    top: -18px;
    left: -5px;
    width: max-content;
  }
  .text-choose {
    color: #d67411;
  }
  .h-full-screen {
    height: calc(100vh - 135px) !important;
  }
}

.car-map-marker-wrapper {
  position: relative;
  .map-marker {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #686868;
    left: -21px;
    bottom: 19px;
    background-color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  .map-marker-con {
    bottom: 3px;
    left: -2px;
    position: absolute;
    height: 16px;
    border: 2px solid #686868;
    transition: 0.3s;
  }
  .map-marker-pointer {
    position: absolute;
    top: -4px;
    left: -4px;
    border: 2px solid #686868;
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
}

.car-map-marker-wrapper-highlighted {
  position: relative;
  z-index: 9999;
  .map-marker {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    left: -32px;
    bottom: 24px;
    background: #d67411;
    border: 2px solid #d67411;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  .map-marker-con {
    bottom: 4px;
    left: -2px;
    position: absolute;
    height: 20px;
    border: 2px solid #d67411;
    transition: 0.3s;
  }
  .map-marker-pointer {
    position: absolute;
    top: -6px;
    left: -6px;
    border: 3px solid #d67411;
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
  svg {
    scale: 1.5;
    path {
      fill: white;
    }
  }
}

.next-spot-marker {
  position: relative;
  .marker {
    position: absolute;
    bottom: 2px;
    left: -12px;
  }
  .marker-pointer {
    position: absolute;
    top: -6px;
    left: -6px;
    border: 3px solid #d67411;
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
}
