.custom-tooltip {
  width: 165px;
  color: #fff;
  background-color: #222;
}

.charts {
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke-opacity: 0;
  }
}
