.lading-list {
  padding: 30px 40px;
  .form-filter-lading {
    padding: 16px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-top: 25px;
    min-width: 1130px;
    .pr-25 {
      padding: 0px !important;
    }
    .ant-col-8 {
      flex: none;
      padding-right: 0 !important;
    }
    .ant-radio-group.ant-radio-group-outline:first-child {
      display: flex;
      .ant-radio-wrapper.ant-radio-wrapper-in-form-item.pr-25:first-child
        > span:last-child {
        width: 63px !important;
      }
      .ant-radio-wrapper.ant-radio-wrapper-in-form-item.pr-25:nth-child(2)
        > span:last-child {
        width: 48px !important;
      }
      .ant-radio-wrapper.ant-radio-wrapper-in-form-item.pr-25:nth-child(3)
        > span:last-child {
        width: 48px !important;
      }
    }
    .ant-col.ant-col-4 {
      .ant-radio-group.ant-radio-group-outline:last-child {
        display: flex;
        .ant-radio-wrapper.ant-radio-wrapper-in-form-item.pr-25:first-child
          > span:last-child {
          width: 64px !important;
        }
      }
    }
  }
  .ant-table-cell {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #171717;
    &:first-child {
      border:none;
      background-color:#ffffff;
    }
  }
  
  .tag-lading-01 {
    background-color: #ffffff;
    border: 1px solid #0076ce;
    color: #0076ce;
  }
  .tag-lading-02 {
    background-color: #0076ce;
    color: #ffffff;
  }
  .tag-lading-03 {
    background-color: #1e9107;
    color: #ffffff;
  }
  .tag-lading-04 {
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #0076ce;
    color: #0076ce;
    border-radius: 4px;
  }
}

.lading-detail {
  padding: 30px 40px;
  .infor-detail-content {
    padding: 16px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-top: 12px;
    animation: aniName ease 1s;
    @keyframes aniName {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .ant-divider-horizontal {
      width: 90%;
      min-width: 90%;
      margin: 8px 0px;
    }
    .ant-divider-vertical {
      border-left: 2px solid rgba(0, 0, 0, 0.06);
    }
  }
  .filter-circle {
    padding: 8px 20px;
    border-radius: 4px;
    margin-top: 25px;
  }
  .table-list-detail {
    .ant-table-container {
      animation: aniName ease 1s;
      @keyframes aniName {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .tag-lading-01 {
        background-color: #ffffff;
        border: 1px solid #0076ce;
        color: #0076ce;
      }
      .tag-lading-02 {
        background-color: #1e9107;
        color: #ffffff;
      }
      .tag-lading-03 {
        background-color: #0076ce;
        color: #ffffff;
      }
      .tag-lading-04 {
        background-color: #dbdbdb;
        color: #171717;
      }
      .tag-lading-05 {
        background-color: #ffffff;
        border: 1px solid #bf3434;
        color: #bf3434;
      }
      .tag-lading-06 {
        background-color: #bf3434;
        color: #ffffff;
      }
      .tag-lading-07 {
        background-color: #bf3434;
        color: #ffffff;
      }
    } 
  }
}
