.assign-create {
  padding: 30px 40px;

  .ant-table-thead tr th {
    border-bottom: unset;
    background-color: unset;
  }

  .ant-table-tbody > tr > td {
    border-top: 1px solid #dbdbdb;
    border-bottom: unset;
  }

  .order-row {
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }

  .change-step-bottom {
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 15px;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    z-index: 10;
    .back-btn {
      position: absolute;
      left: 20px;
      display: flex;
      align-items: center;
    }
  }
  .collapsed {
    width: calc(100vw - 80px);
    left: 80px;
  }
  .un-collapsed {
    width: calc(100vw - 240px);
    left: 240px;
  }

  .confirm-assign {
    .list-info-services {
      max-height: 700px;
      overflow-y: auto;
      .info-service {
        border-bottom: 1px solid #dbdbdb;
        height: 100%;
        padding-bottom: 10px;
      }
    }

    .ant-collapse {
      background-color: #fff;
      border: unset;
      .ant-collapse-content {
        border: unset;
      }
    }

    .ant-collapse-item {
      .ant-collapse-arrow {
        transform: translateY(-50%) rotate(90deg) !important;
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-arrow {
        transform: translateY(-50%) rotate(180deg) !important;
      }
    }
  }
}

.assign-list {
  .form-filter-assign-setting {
    padding: 16px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-top: 25px;
    min-width: 1130px;
    margin-bottom: 25px;
    .pr-25 {
      padding: 0px !important;
    }
  }
  .table-list-assign-setting {
    margin-bottom: 64px;
  }
  .assign-list-footer {
    background-color: #fff;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
  }
}
