.home {
  .ant-table {
    position: relative;
    .ant-table-title {
      padding: 0;
      .table-title-home {
        position: absolute;
        bottom: -40px;
      }
    }
    .ant-table-tbody {
      .ant-table-row-level-0:nth-child(1) {
        tr,
        td {
          border-top: 1px solid #dbdbdb;
        }
      }
    }
  }
}
