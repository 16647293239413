.modal-create-user {
  .ant-modal-body {
    padding: 32px 40px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    .back-btn {
      display: flex;
      align-items: center;
    }
  }
  .ant-upload-list.ant-upload-list-text {
    margin-top: 12px;
    display: flex;
    flex-direction: column-reverse;
  }
  .ant-form-item.form-item.borderless {
    padding-bottom: 0;
  }
}
.bg-image-default {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'%3E%3Ccircle cx='40' cy='40' r='40' fill='%23D2E9FA'/%3E%3Cmask id='mask0_31_1821' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='80' height='80'%3E%3Ccircle cx='40' cy='40' r='40' fill='%23CFCFCF'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_31_1821)'%3E%3Cpath d='M15 81.25C15 74.6196 17.6339 68.2607 22.3223 63.5723C27.0107 58.8839 33.3696 56.25 40 56.25C46.6304 56.25 52.9893 58.8839 57.6777 63.5723C62.3661 68.2607 65 74.6196 65 81.25H15ZM40 53.125C29.6406 53.125 21.25 44.7344 21.25 34.375C21.25 24.0156 29.6406 15.625 40 15.625C50.3594 15.625 58.75 24.0156 58.75 34.375C58.75 44.7344 50.3594 53.125 40 53.125Z' fill='%230059BF'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}
.modal-company {
  .ant-modal-body {
    .ant-row.borderless {
      border-bottom: 1px solid #dbdbdb;
    }
  }
}
.modal-car {
  .ant-modal-body {
    .ant-row.borderless {
      border-bottom: 1px solid #dbdbdb;
    }
  }
}
.modal-map {
  .ant-modal-header {
    border: none;
  }
  .ant-modal-body {
    padding: 0px 30px !important;
    padding-bottom: 13px !important;
  }
}
