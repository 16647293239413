.infor-trip {
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 12px;
  .time-title {
    color: #686868;
    font-weight: 700 !important;
    font-size: 14px;
  }
  .id-trip {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #0076ce;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
  .icon-arrow {
    cursor: pointer;
    transform: rotate(180deg);
  }
  .grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:focus {
      outline: none !important;
    }
  }

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  .grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .arrow-down {
    position: absolute;
    width: 32px;
    height: 32px;
    transform: translateX(-50%);
    left: 50%;
    bottom: -42px;
  }
  &.active {
    border-color: #d67411;
    .id-trip {
      background: #d67411;
    }
  }
  &.dropdown-active {
    .item {
      height: 38px;
      border-top: 1px solid #dbdbdb;
      .label {
        background: #f7f7f7;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 12px;
      }
    }
    .item:last-child {
      // border-bottom: 1px solid #dbdbdb;
      margin-bottom: 12px;
    }
    .icon-arrow {
      transform: rotate(360deg);
    }
  }
  .tsumi {
    border: 1px solid #2E91F3;
    border-radius: 5px;
    padding: 0px 4px;
    color: #2E91F3;
  }
  .oroshi {
    border: 1px solid #00B957;
    border-radius: 5px;
    padding: 0px 4px;
    color: #00B957;
  }
  .timeInfo {
    padding: 11.5px 0px 18px 59px;
  }
}

.map-marker-wrapper,
.map-marker-wrapper-highlighted {
  position: relative;
  .map-marker {
    position: absolute;
    bottom: 20px;
    left: -16px;
    background: #ffffff;
    border: 2px solid #0076ce;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 38px;
    color: #0076ce;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    transition: 0.3s;
  }
  .map-marker-con {
    position: absolute;
    bottom: 8px;
    left: 3px;
    height: 12px;
    border: 1px solid #0076ce;
    transition: 0.3s;
  }
  .map-marker-pointer {
    width: 8px;
    height: 8px;
    border: 2px solid #0076ce;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
}

.map-marker-wrapper-highlighted {
  .map-marker {
    bottom: 22px;
    left: -28px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    border: none;
    background: #d67411;
    color: #ffffff;
    font-size: 32px;
    z-index: 10;
  }
  .map-marker-con {
    border: 1px solid #d67411;
    bottom: 10px;
    left: 4px;
  }
  .map-marker-pointer {
    border: 2px solid #d67411;
    width: 10px;
    height: 10px;
  }
}

.spin-loading {
  &.ant-spin-nested-loading > div > .ant-spin {
    position: fixed;
    left: 160px;
    top: 0;
    width: calc(100% - 160px);
    height: calc(100% - 60px);
    max-height: none;
  }
}

.info-trip_destination {
  display: inline-block;
  width: 80px;
  position: relative;
  top: 4px;
}

.cargo {
  word-wrap: break-word;
}

.rotation-selection {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-left: 32px;
  padding: 8px 20px;
}