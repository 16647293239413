.collect-money-summary-area {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 10px;
  width: 50vw;
}
.collect-money-over-message {
  margin: 5px;
  color: red;
}